import Api from "../api";

import { errorHandler } from "../common";
const { axios: api } = Api;

const queryString = require("query-string");

// export const getPaymentReportForConsultant = (payload) =>
//   errorHandler(async () => {
//     const data = await api.get(`/report/consultant/payment/${queueId}`);
//     return { data: data.data };
// });

// export const getPaymentReport = (payload) =>
//   errorHandler(async () => {
//     const data = await api.get(`/report/payment/${queueId}`);
//     return { data: data.data };
// });

// export const getEffectiveReportForConsultant = (payload) =>
//   errorHandler(async () => {
//     const data = await api.get(`/report/consultant/effective/${queueId}`);
//     return { data: data.data };
// });

// export const getEffectiveReport = (payload) =>
//   errorHandler(async () => {
//     const data = await api.get(`/report/effective/${queueId}`);
//     return { data: data.data };
// });

export const getDailyUsage = () =>
  errorHandler(async () => {
    const data = await api.get(`/report/get-daily-usage`);
    return { data: data.data };
  });

export const handleLoadLastSevenDaysHoursAndConfirmedOrders = () =>
  errorHandler(async () => {
    const data = await api.get(
      "/report/load-last-seven-days-hours-and-confirmed-orders/"
    );
    return { data: data.data };
  });

export const getConsultantDailyContactsHistory = () =>
  errorHandler(async () => {
    const data = await api.get("/report/daily-contacts/");
    return { data: data.data };
  });

export const getCCEffectiveSalaryPaymentKDR = (month, company) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/cc-salary-effective-kdr/${month}/${company}`
    );
    return { data: data.data };
  });

export const getCCEffectiveSalaryPayment = (month) =>
  errorHandler(async () => {
    const data = await api.get("/report/cc-salary-effective/" + month);
    return { data: data.data };
  });

export const getMarketsDailyReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/daily-report/market/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getOrderLostPackagesReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/lost-packages/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getMarketWppkReportForMarket = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/wppk/market/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getMarketWppkReportForConsultant = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/wppk/consultant/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getUtmCampaignWPPK = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/wppk/utm-campaign/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getUtmGampaingWPPKChart = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/wppk/utm-campaign-chart/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getConsultantGrade = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/consultant/grade/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getCallHitoryStatisticts = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `report/get_calling_history_by_filters?${queryString.stringify(
        cleanObject(payload),
        { arrayFormat: "bracket" }
      )}`
    );

    return { data: data.data };
  });

export const getConsultantGradeForNewConsultant = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/consultant/grade/new_consultant/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getClaimsReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      "/report/get_claims?" +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getInvoicesReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      "/report/get_invoices?" +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getValidInvoicesReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      "/report/get_valid_invoices?" +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getInoicesByStatusesReport = () =>
  errorHandler(async () => {
    const data = await api.get("/report/invoices_by_statuses");

    return { data: data.data };
  });

export const getClaimsByStatusReport = () =>
  errorHandler(async () => {
    const data = await api.get("/report/claims_by_statuses");

    return { data: data.data };
  });

export const getValidClaisByStatusReport = () =>
  errorHandler(async () => {
    const data = await api.get("/report/valid-claims-by-statuses");

    return { data: data.data };
  });

export const getValidClaimsToCorrect = () =>
  errorHandler(async () => {
    const data = await api.get("/report/valid-claims-corrections");

    return { data: data.data };
  });

export const getNotPickUpCalls = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/not-pick-up-calls/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getConsultantEffectiveSalary = (_id, from, to) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/consultant/effective_salary/${_id}?` +
        queryString.stringify(cleanObject({ from, to }), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

export const getEffectiveSummarizeConsultant = (_id, marketUsers, months) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/consultant/effective-sumarize-consultant-salary/${_id}?` +
        queryString.stringify(cleanObject({ months, marketUsers }), {
          arrayFormat: "bracket",
        })
    );
    return { data: data.data };
  });

export const getOrderSentReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/sent-orders/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getOrderSentChart = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/sent-orders-chart/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getOrderLangPairClaimReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/lang-pair-claim/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getMoreInfoTestReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/more-info-test/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getEffectivePaymentReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/effective_salary/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getGradeReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/grade/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getConsultantsDailyReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/daily/?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getTeamCurScore = () =>
  errorHandler(async () => {
    const data = await api.get(`/report/team/grade`);
    return { data: data.data };
  });

export const getTeamResult = () =>
  errorHandler(async () => {
    const data = await api.get(`/report/team/result`);
    return { data: data.data };
  });

export const getVoipUserWorktime = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/voip/worktime?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getConsultantTakenContacts = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/contacts/road?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getQueueLastWeekDownloadStats = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/queue-last-week-download?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getDonutMarketStats = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/donut-market-stats?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getDailyConfirmedChart = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/daily-confirmed-chart?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    )
    return { data: data.data };
  });

export const getCPLStats = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/cpl-stats?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getQueueLastWeekDeliverity = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/queue-last-deliverity?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getLastConsultantDeliveirty = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/consultant-last-deliverity?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });
export const getLastMarketDeliveirty = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/market-last-deliverity?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getWeeklyResignDeliverability = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/resign-deliverity?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getWorkingHoursLastSevenDays = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/working-hours-last-seven-days?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getLastMonthLeads = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/last-thirty-days-leads?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getPaidLeads = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/paid-leads?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getLastConsultantWPPK = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/weekly-consultant-wppk?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getLastMarketWPPK = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/weekly-market-wppk?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );
    return { data: data.data };
  });

export const getMetaAddsSpend = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/meta-adds-spend?` +
        queryString.stringify(cleanObject(payload), { arrayFormat: "bracket" })
    );

    return { data: data.data };
  });

export const getSuspicions = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/suspicions?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );
    return { data: data.data };
  });

export const getLangugeStats = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/lang-stats?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );
    return { data: data.data };
  });

export const getLanguageMonthStats = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/lang-month-stats?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );
    return { data: data.data };
  });

export const getSuspicionsMakers = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/suspicions-makers?${queryString.stringify(cleanObject(payload), {
        arrayFormat: "bracket",
      })}`
    );
    return { data: data.data };
  });

export const getCPLProductReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/product-cpl?${queryString.stringify(cleanObject(payload))}`,
      {
        arrayFormat: "bracket",
      }
    );

    return { data: data.data };
  });

export const getCPLPaymentProductReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/payment-product-cpl?${queryString.stringify(
        cleanObject(payload)
      )}`,
      {
        arrayFormat: "bracket",
      }
    );

    return { data: data.data };
  });

export const getSupportResignsReport = (payload) =>
  errorHandler(async () => {
    const data = await api.get(
      `/report/support-resign?${queryString.stringify(cleanObject(payload))}`,
      {
        arrayFormat: "bracket",
      }
    );

    return { data: data.data };
  });

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== "")
  );
};
