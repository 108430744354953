import React, { useState } from 'react'
import Icon from '@/common/components/Icon'
import { formatToDateTamplate } from '@/common/functions/dateFormater'
import { useCommonDataContext } from '@/common/hooks/commonDataContext'
import { findUserById } from '@/common/functions/findUserById'
import { CopyItem, InfoItem, InfoItemLabel, InfoItemsWrapper, InfoItemValue, SectionWrapper, Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import CheckBoxInput from '../../../common/checkBoxInput/CheckBoxInput'
import { Colors } from '@/common/colors/colors'
import { VALID_CLAIM_PRIORITIES } from '@/common/constants/priorities'
import { useNewMessageQueueContext } from '@/common/hooks/useNewMessageQueue'

const ContactInformation = ({ 
  contact, 
  order,
  claimPriority,
  setClaimPriority,
  claim,
}) => {
  const [isMailCopied, setIsMailCopied] = useState(false);

  const { commonData: { users }}  = useCommonDataContext();

  const { addMessage } = useNewMessageQueueContext();

  const handleCopiedMail = () => {
    navigator.clipboard.writeText(contact?.email);
    setIsMailCopied(true);
    setTimeout(() => setIsMailCopied(false), 200);
    addMessage("Copied", "success", 500)
  } 

  const handleOpenContact = () => {
    window.open(`${window.location.origin}/dashboard/contact/${contact._id}`, "_blank")
  }

  return (
    <SectionWrapper>
      <Subtitle>
        Information
      </Subtitle>
     <InfoItemsWrapper>
      <InfoItem>
        <InfoItemLabel>
          Name:
        </InfoItemLabel>
        <InfoItemValue
          cursor="pointer"
          onClick={() => handleOpenContact()} 
        >
          {contact?.full_name} 
          <Icon name="fa fa-address-book" />
        </InfoItemValue>
      </InfoItem>

      <InfoItem>
        <InfoItemLabel>
          Sold date:
        </InfoItemLabel>
        <InfoItemValue>
          {formatToDateTamplate(
            order.shipping.status_delivered_at 
            || order.shipping.status_delivered_no_payment_at
          )}
        </InfoItemValue>
      </InfoItem>

      <InfoItem>
        <InfoItemLabel>
          Mail:
        </InfoItemLabel>
        <InfoItemValue
          cursor="pointer"
          onClick={() => handleCopiedMail()}
        >
          <CopyItem isCopied={isMailCopied}>
            {contact?.email} 
          </CopyItem>
          <Icon name="fa fa-copy" />
        </InfoItemValue>
      </InfoItem>

      <InfoItem>
        <InfoItemLabel>
          Consultant:
        </InfoItemLabel>
        <InfoItemValue>
          {findUserById(order.confirmed_by, users)?.username}
        </InfoItemValue>
      </InfoItem>

      <InfoItem>
        <InfoItemLabel style={{ alignSelf: "flex-start"}}>
          Priority:
        </InfoItemLabel>
        <InfoItemValue>
          <CheckBoxInput
            disabled={claim}
            checked={claimPriority === VALID_CLAIM_PRIORITIES.NONE.value}
            handleSelect={() => setClaimPriority(VALID_CLAIM_PRIORITIES.NONE.value)} 
            text={VALID_CLAIM_PRIORITIES.NONE.label} 
          />
          <CheckBoxInput
            disabled={claim}
            checked={claimPriority === VALID_CLAIM_PRIORITIES.HIGH.value}
            handleSelect={() => setClaimPriority(VALID_CLAIM_PRIORITIES.HIGH.value)}
            mainColor={Colors.red} 
            accentColor="#fff" 
            text={VALID_CLAIM_PRIORITIES.HIGH.label} 
            color="#fff"
          />
        </InfoItemValue>
      </InfoItem>
     </InfoItemsWrapper>
    </SectionWrapper>
  )
}

export default ContactInformation
