import { Colors } from '@/common/colors/colors';
import styled from 'styled-components';

export const Textarea = styled.textarea`
  width: 500px;
  height: 300px;
  resize: none;
  outline: none;
  border: 2px solid ${Colors.darkBlue};
  border-radius: 5px;
  font-size: 14px;
  background-color: ${Colors.lightGray2};
  color: ${Colors.darkBlue};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
