export const CONVERSION_NAMES = {
	BANK_CODE: 'bank_code',
	ACCOUNT: 'account',
	PREFIX: 'prefix',
}

export const CONVERSION_OPTIONS = [
	{ label: "Bank code", value: CONVERSION_NAMES.BANK_CODE },
	{ label: "Account", value: CONVERSION_NAMES.ACCOUNT },
	{ label: "Prefix", value: CONVERSION_NAMES.PREFIX },
]


export const ACCOUNTS_CONVERSION = {
	CZ: {
		Prefix: {
			required: false,
			placeholder: '######',
			pattern: /^[0-9]{0,6}$/,
			name: CONVERSION_NAMES.PREFIX,
		},
		"Account *": {
			required: true,
			placeholder: '##########',
			pattern: /^[0-9]{2,10}$/,
			name: CONVERSION_NAMES.ACCOUNT,
		},
		"Bank code *": {
			required: true,
			placeholder: '####',
			pattern: /^[0-9]{4}$/,
			name: CONVERSION_NAMES.BANK_CODE,
		},
	},
	HU: {
		"Account *": {
			required: true,
			placeholder: '########-########-########',
			pattern: /^[0-9]{8}-[0-9]{8}(-[0-9]{8})?$/,
			name: CONVERSION_NAMES.ACCOUNT,		
		},
	},
	LT: {
		"Bank code *": {
			required: true,
			placeholder: 'Bank/Branch code',
			name: CONVERSION_NAMES.BANK_CODE,
		},
		"Account *": {
			required: true,
			placeholder: 'Account number',
			name: CONVERSION_NAMES.ACCOUNT
		},
	},
	SK: {
		Prefix: {
			required: false,
			placeholder: '######',
			pattern: /^[0-9]{0,6}$/,
			name: CONVERSION_NAMES.PREFIX,
		},
		"Account *": {
			required: true,
			placeholder: '##########',
			pattern: /^[0-9]{2,10}$/,
			name: CONVERSION_NAMES.ACCOUNT,
		},
		"Bank code *": {
			required: true,
			placeholder: '####',
			pattern: /^[0-9]{4}$/
,
			name: CONVERSION_NAMES.BANK_CODE,
		},
	},
	RO: {
		"Bank code *": {
			required: true,
			placeholder: 'SWIFT/BIC code',
			name: CONVERSION_NAMES.BANK_CODE,
		},
		"Account *": {
			required: true,
			placeholder: 'Account number',
			name: CONVERSION_NAMES.ACCOUNT
		},
	},
	HR: {
		"Bank code *": {
			required: true,
			placeholder: 'Bank/Branch code',
			name: CONVERSION_NAMES.BANK_CODE,
		},
		"Account *": {
			required: true,
			placeholder: 'Account number',
			name: CONVERSION_NAMES.ACCOUNT
		},
	},
	LV: {
		"Bank code *": {
			required: true,
			placeholder: 'SWIFT/BIC code',
			name: CONVERSION_NAMES.BANK_CODE,
		},
		"Account *": {
			required: true,
			placeholder: 'Account number',
			name: CONVERSION_NAMES.ACCOUNT
		},
	},
};
