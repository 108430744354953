import React from 'react'
import ClaimType from './components/claimType/ClaimType'
import OrderItems from './components/orderItems/OrderItems'
import Reasoning from './components/reasoning/Reasoning'
import { getMaxClaimDate } from '@/common/functions/getMaxClaimDate'
import { ClaimSideWrapper } from './ClaimSide.styled'
import ClaimPayment from './components/claimPayment/ClaimPayment'
import ClaimsStatus from './components/claimStatus/ClaimsStatus'
import { genItemsIds } from '../../helpers/getItemsIds'

const ClaimSide = ({
  tagsOptions,
  order,
  claimType,
  setClaimType,
  selectedOrderItems,
  setSelectedOrderItems,
  selectedTags,
  setSelectedTags,
  loadTags,
  claimReason,
  setClaimReason,
  feedbackRef,
  claimStatus,
  claim,
  handleValidClaimUpdate,
  contact,
  claimMethod,
  setClaimMethod,
  ibanRef,
	ibanCountry,
	setIbanCountry,
  accountsDataState,
  dispatchAccountsData
}) => {
  return (
    <ClaimSideWrapper>
      <ClaimType
        claim={claim}
        maxClaimDate={getMaxClaimDate(claim)} 
        claimType={claimType} 
        setClaimType={setClaimType} 
      />
      <OrderItems 
        claim={claim}
        selectedOrderItems={selectedOrderItems} 
        setSelectedOrderItems={setSelectedOrderItems} 
        currency={claim?.currency || order.shipping?.currency || order.currency} 
        orderItems={claim?.order_items || genItemsIds(order)} 
      />
      <Reasoning
        claim={claim}
        feedbackRef={feedbackRef}
        tagsOptions={tagsOptions}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        loadTags={loadTags}
        claimReason={claimReason}
        setClaimReason={setClaimReason}
      />
      <ClaimPayment
        claimStatus={claimStatus}
        ibanRef={ibanRef}
        claimMethod={claimMethod}
        setClaimMethod={setClaimMethod}
        contact={contact}
        claim={claim}
        order={order}
				ibanCountry={ibanCountry}
				setIbanCountry={setIbanCountry}
        accountsDataState={accountsDataState}
        dispatchAccountsData={dispatchAccountsData}
      />
      <ClaimsStatus 
        handleValidClaimUpdate={handleValidClaimUpdate} 
        claimStatus={claimStatus} 
      />
   </ClaimSideWrapper>
  )
}

export default ClaimSide
