export const ALL_ACCESSES = [
  "USER_CREATE",
  "USER_CREATE_BY_CSV",
  "USER_GET",
  "USER_GET_BY_ID",
  "USER_UPDATE",
  "USER_CRM_REQUESTS_HISTORY_GET",
  "CRM_SETTINGS_USERS",

  "CC_GAMIFICATION",

  "PERSONAL_TASKS_GET",
  "GENERAL_TASKS_GET",
  "BOUND_GET",
  "BOUND_DELETE",
  "CONTACT_CREATE",
  "DASHBOARD_CREATE",
  "DASHBOARD_GET",
  "DASHBOARD_UPDATE",
  "DASHBOARD",
  "PERSONAL_TASKS",
  "GENERAL_TASKS",
  "BOUND",
  "CONTACT_NEW",

  "CONTACT_GET_WITH_FILTERS",
  "CONTACT",

  "ORDER_GET_ALL_FILTERS",
  "ORDER_GET_FILTERED",
  "ORDER_GET_FILTER",
  "ORDER_GET_POSSIBLE_FIELDS",
  "ORDER_CREATE_FILTER",
  "ORDER_UPDATE_FILTER",
  "ORDER",

  "AWS_GET",
  "AWS_GET_BY_ID",
  "AWS_DELETE_BY_ID",
  "AWS_SAVE_FILE",
  "AWS_SAVE_DRAFT",
  "AWS_GET_RECORDING_BY_ID",

  "CLAIM_CHANGE_STATUS",
  "CLAIM_SEARCH",
  "CLAIM_UPDATE",
  "CLAIM_MARK_DONE",
  "ORDER_MARK_PAID",
  "CLAIMS_BY_PRODUCT",
  "CLAIM_BY_ORDER",
  "ACCOUNTING_CLAIMS_REPORT_GET",
  "ACCOUNTING_INVOICES_REPORT_GET",
  "ACCOUNTING",
  "ACCOUNTING_CLAIMS_SEARCH",
  "ACCOUNTING_INVOICES",
  "ACCOUNTING_CLAIMS",
  "ACCOUNTING_CLAIMS_REPORT",
  "ACCOUNTING_INVOICES_REPORT",

  "CC_SEARCH",
  "CC_ACCEPT",
  "CC_SALARY_EFFECTIVE",
  "CC_SALARY_EFFECTIVE_KDR",
  "CC_SAVE_CONSULTANT_HOURS",
  "CC",
  "CC_HOURS",
  "CC_HOURS_REPORT",
  "CC_PAYMENT",
  "CC_PAYMENT_KDR",
  "CC_SPEECH_TO_TEXT",
  "CC_RECRUITMENT",

  "TICKETS_UPDATE_INVOICE",
  "TICKETS_UPDATE_DOCUMENT",
  "TICKETS_SEARCH_INVOICES",
  "TICKETS_CHECK_MESSAGE",
  "TICKETS_QUESTION_CREATE",
  "TICKETS_FILE_UPLOAD",
  "TICKETS_CORRECTION_CREATE",
  "TICKETS_ADD_MESSAGE",
  "TICKETS_MARK_CLOSE",
  "TICKETS_MARK_OVERDUE",
  "TICKETS_MARK_TO_CORRECT",
  "TICKETS_MARK_CORRECTED",
  "TICKETS_MARK_ISSUED",
  "TICKETS_MARK_DONE",
  "TICKETS_GET_BY_FILTER",
  "TICKETS_CALLING_IMPROVEMENT_CREATE",
  "TICKETS_ADD_CORRECTION_NUMBER",
  "TICKETS",

  "TRANSLATION_GET",
  "TRANSLATION_SEARCH",
  "TRANSLATION_UPDATE",
  "TRANSLATION_ADD",
  "CRM_SETTINGS_DICTIONARY",

  "SEARCH_ORDERS",
  "SEARCH_CONTACT_WITH_ORDERS",
  "CONTACT_UPDATE",
  "MARK_RODO_DECISION",
  "MAIL_SEND_MORE_INFO",
  "MAIL_CONFIRMATION",
  "MAIL_GET_BY_CONTACT",
  "ORDER_GET_WITH_CONTACT",
  "ORDER_RESEND",
  "ORDER_CONFIRM",
  "ORDER_CREATE_SHIPPING",
  "ORDER_EDIT",
  "ORDER_MARK_RESIGNED",
  "ORDER_UPDATE_DELIVERY_DATE",
  "ORDER_MARK_CLAIM",
  "ORDER_GET_HISTORY_BY_ID",
  "TICKETS_CREATE_INVOICE",
  "USER_GET_SHORT_INFO",

  "QUEUE_CREATE",
  "QUEUE_GET_LIST_OF_INFOS",
  "QUEUE_GET",
  "QUEUE_GET_BY_ID",
  "QUEUE_GET_STACK",
  "QUEUE_POSSIBLE_CONTACTS",
  "QUEUE_UPDATE",
  "QUEUE_GET_POINTS",
  "QUEUE_GET_CURRENT_WORKERS",
  "QUEUE_GROUP_GET",
  "QUEUE_GROUP_DELETE",
  "QUEUE_GROUP_CREATE",
  "QUEUE_GROUP_UPDATE",
  "QUEUE_GROUP_GET_BY_ID",
  "CRM_SETTINGS_QUEUES",
  "CRM_SETTINGS_LANGUAGE",
  "CRM_SETTINGS_RECRUITMENT",
  "CRM_SETTINGS_POSITION",
  "CRM_SETTINGS_DYNAMIC_MAIL_CONTENT",
  "CRM_SETTINGS_DYNAMIC_MAIL_ATTACHMENT",
  "CRM_SETTINGS_FILES",

  "MARKET_CREATE",
  "MARKET_GET",
  "MARKET_GET_BY_ID",
  "MARKET_UPDATE",
  "CRM_SETTINGS_MARKETS",

  "PRODUCT_CREATE",
  "PRODUCT_GET",
  "PRODUCT_GET_BY_ID",
  "PRODUCT_UPDATE",
  "CRM_SETTINGS_PRODUCTS",

  "DRAFTS_GET",
  "DRAFTS_CREATE",
  "DRAFTS_UPDATE",
  "CRM_SETTINGS_DRAFTS",

  "BLACKBOARD_GET",
  "BLACKBOARD_UPDATE",
  "BLACKBOARD_CREATE",
  "CRM_SETTINGS_BLACKBOARD",

  "LISTENING_GENERATOR_CREATE",
  "LISTENING_GENERATOR_GET",
  "LISTENING_GENERATOR_UPDATE",
  "CRM_SETTINGS_LISTENING_GENERATOR",

  "FAQ_GET",
  "FAQ_UPDATE",
  "FAQ_CREATE",
  "CRM_SETTINGS_FAQ",

  "MAIL_TEST",
  "MAIL_SEARCH",
  "MAIL",
  "MAIL_ORDERS_QUERY",
  "MAIL_CATEGORY_VIEW",
  "MAIL_TEMPLATE_VIEW",
  "MAIL_TEST_VIEW",
  "MAIL_CATEGORY_GET_ALL",
  "MAIL_CATEGORY_CREATE",
  "MAIL_CATEGORY_UPDATE",
  "MAIL_TEMPLATE_GET_ALL",
  "MAIL_TEMPLATE_CREATE",
  "MAIL_TEMPLATE_UPDATE",
  "MAIL_CATEGORY_TEST",
  "MAIL_TEST_GET_ALL",
  "MAIL_TEST_CREATE",
  "MAIL_TEST_UPDATE",
  "MAIL_RECRUITMENT_CATEGORY",

  "REPORTS_NOT_PICK_UP",
  "REPORTS_CALLING_HISTORY",
  "REPORTS_GRADE",
  "REPORTS_EFFECTIVE_SALARY",
  "REPORTS_WPPK_FOR_MARKET",
  "REPORTS_WPPK_FOR_CONSULTANT",
  "REPORTS_EFFECTIVE",
  "REPORTS_DAILY",
  "REPORTS_SENT_ORDERS",
  "REPORTS_DAILY_MARKET",
  "REPORTS_CONTACTS_ROAD",
  "REPORTS_UTM",
  "REPORTS_CPL",
  "REPORTS_PRODUCT_CPL",
  "REPORTS_LANG_PAIR",
  "REPORTS_LOST_PACAKAGE",
  "REPORTS_SUSPICIONS",
  "REPORTS_RESIGN",
  "REPORTS_ORDER_LANGS_STATS",
  "REPORTS",

  "VOIP_FIND_SMS",
  "CLOUD_TALK_GET_NUMBERS",
  "SMS_GET_TEMPLATE",
  "SMS_UPDATE_TEMPLATE",
  "DELETE_SMS_TEMPLATE",
  "SMS_DELETE_TEMPLATE",
  "SMS_CREATE_TEMPLATE",
  "CALLING_GET_BY_FILTER",
  "VOIP_GET_REALTIME_DATA",
  "VOIP_GET_MARKET_STATISTICS",
  "REPORT_VOIP_WORKING_TIME",
  "VOIP_GET_SPECIAL_MARKET_STATISTICTS",
  "VOIP_GET_ADMIN_RECORDED_CALLS",
  "VOIP_LISTENING_SCORING_REPORT_GET",
  "VOIP_LISTENING_SCORING_REPORT_UPDATE",
  "ORDER_GET_ARCHIVES",
  "ORDER_ADD_ARCHIVES",
  "VOIP_SMS_SEARCH",
  "VOIP_SMS_CREATE",
  "VOIP_CALLING_SEARCH",
  "VOIP_MARKS",
  "VOIP_REALTIME",
  "VOIP_WORKING_TIME",
  "VOIP_STATISTICS",
  "VOIP_LISTENING",
  "VOIP_SCORING",
  "VOIP_ARCHIVE",
  "VOIP",

  "LISTENING_GENERATOR_DELETE",

  "CALLING_STATISTICS_GET",
  "QUEUE_GET_STACK_AND_CONTACTS",
  "CLAIMS_GET_BY_STATUSES",
  "INVOICES_GET_BY_STATUSES",
  "ARCHIVES_GET_ALL",
  "MAIL_GET_HISTORY",
  "BOUND_INFO_DASHBOARD",
  "YOUR_TICKETS_DASHBOARD",
  "CREATE_CONTACT_DASHBOARD",
  "HOLIDAY_TICKETS_DASHBOARD",
  "MAIL_HISTORY_DASHBOARD",
  "LOGGED_IN_USERS_DASHBOARD",
  "EFFECTIVITY_REPORT_DASHBOARD",
  "CONSULTANT_HOURS_DASHBOARD",
  "ARCHIVES_DASHBOARD",
  "ORDER_SEARCH_DASHBOARD",
  "INVOICES_DASHBOARD",
  "CLAIMS_DASHBOARD",
  "VOIP_STATISTICS_DASHBOARD",
  "CURRENT_LEADS_DASHBOARD",
  "CALLING_STATISTICS_DASHBOARD",

  "CALLING_STATS_DASHBOARD",
  "CONSULTANT_INFO_DASHBOARD",
  "DONUT_CALLING_STATS_DASHBOARD",
  "DONUT_MARKET_STATS_DASHBOARD",
  "HOURS_CONSULTANT_DASHBOARD",
  "MAIL_STATS_DASHBOARD",
  "WPPK_CONFIRMED_DASHBOARD",
  "WPPK_DELIVERED_DASHBOARD",
  "DELIVERY_MARKET_DASHBOARD",
  "DELIVERY_CONSULTANT_DASHBOARD",
  "CONTACT_WEIGHTS_DASHBOARD",
  "LEADS_DASHBOARD",
  "SENT_ORDERS_DASHBOARD",
  "WPPK_DELIVERED_UTM_DASHBOARD",
  "WPPK_CONFIRMED_MARKET_DASHBOARD",
  "WPPK_DELIVERED_MARKET_DASHBOARD",
  "CPL_STATS_DASHBOARD",
  "CALLING_DAILY_STATS_DASHBOARD",

  "DW_UPDATE",
  "TICKET_MARK_SEEN",
  "TICKETS_GET_OLD",
  "UPDATE_TITLE",
  "TICKET_ADD_ATTACHMENTS",

  "META_CREATE",
  "META_UPDATE",
  "META_GET",
  "META_ADD_SPEND_REPORT",
  "META_CPL_STATS_REPORT",
  "CRM_SETTINGS_META",

  "DAILY_USAGE_QUEUE",

  "GAMIFICATION_CONFIG_CREATE",
  "GAMIFICATION_CONFIG_GET",
  "GAMIFICATION_CONFIG_GET_ALL",
  "GAMIFICATION_CONFIG_UPDATE",
  "GAMIFICATION_STATS_GET",

  "GET_COMPANY_DATA_BY_VAT",
  "GET_COMPANY_DATA_BY_NUMBER",
  "CREATE_LANUGAGE",
  "UPDATE_LANGUAGE",
  "VALID_INVOICES_REPORT_GET",
  "VALID_INVOICE_CREATE",
  "GENERATE_INVOICE_PREVIEW",
  "VALID_INVOICE_UPDATE",
  "VALID_INVOICE_ACCEPT",
  "VALID_INVOICES_GET",

  "CRM_SETTINGS_INVOICE_TEMPLATE",
  "INVOICE_TEMPLATE_CREATE",
  "INVOICE_TEMPLATE_UPDATE",
  "INVOICE_TEMPLATE_GET",

  "KEYS_CREATE",
  "KEYS_UPDATE",
  "KEYS_GET_ALL",
  "KEYS_GET_BY_TYPE",
  "CRM_SETTINGS_KEYS",

  "COMPANY_CREATE",
  "COMPANY_UPDATE",
  "COMPANY_GET",
  "CRM_SETTINGS_COMPANY",

  "INVOICE_CONFIG_CREATE",
  "INVOICE_CONFIG_UPDATE",
  "INVOICE_CONFIG_GET",
  "CRM_SETTINGS_INVOICE_CONFIG",

  "CRM_SETTINGS_DEPARTMENT",
  "DEPARTMENT_CREATE",
  "DEPARTMENT_UPDATE",

  "CMS_REFUND",
  "CONSULTANT_MANAGER",
  "CRM_SETTINGS",
  "COMMON",
  "CONSULTANT_COMMON",
  "GET_ADMIN_TODOS",

  "CRM_SETTINGS_BONUS",
  "BONUS_CREATE",
  "BONUS_UPDATE",

  "CRM_SETTINGS_PROMOTION",
  "PROMOTION_CATEGORY_GET_ALL",
  "PROMOTION_CATEGORY_TEST",
  "PROMOTION_CATEGORY_CREATE",
  "PROMOTION_CATEGORY_UPDATE",
  "PROMOTION_CATEGORY_QUERY",

  "GET_CALL_CENTER_CANDIDATES",
  "CREATE_CALL_CENTER_CANDIDATE",
  "UPDATE_CALL_CENTER_CANDIDATE",
  "GET_CALL_CENTER_POSITIONS",
  "CREATE_CALL_CENTER_POSITION",
  "UPDATE_CALL_CENTER_POSITION",
  "GET_DYNAMIC_MAIL_ATTACHMENT",
  "CREATE_DYNAMIC_MAIL_ATTACHMENT",
  "UPDATE_DYNAMIC_MAIL_ATTACHMENT",
  "GET_DYNAMIC_MAIL_CONTENT",
  "CREATE_DYNAMIC_MAIL_CONTENT",
  "UPDATE_DYNAMIC_MAIL_CONTENT",
  "GET_FILES",
  "CREATE_FILE",
  "UPDATE_FILE",
  "MAIL_RECRUITMENT_CATEGORY_GET_ALL",
  "MAIL_RECRUITMENT_CATEGORY_TEST",
  "MAIL_RECRUITMENT_CATEGORY_CREATE",
  "MAIL_RECRUITMENT_CATEGORY_UPDATE",
  "MAIL_CANDIDATES_QUERY",
  "GET_RECRUITMENT_PAGES",
  "CREATE_RECRUITMENT_PAGE",
  "UPDATE_RECRUITMENT_PAGE",

  "VALID_CLAIM_CREATE",
  "VALID_CLAIMS_GET",
  "VALID_CLAIMS_UPDATE",
  "VALID_CLAIMS_PAY",
  "VALID_CLAIMS_DASHBOARD",
  "VALID_CLAIMS_FINANCE_DASHBOARD",
];

const ACCESSES = {
  ADMIN: ALL_ACCESSES,
  CALL_CENTER_MANAGER: ["CONSULTANT_COMMON", "CONSULTANT_MANAGER", "COMMON"],
  CALL_CENTER_OPERATOR: ["CONSULTANT_COMMON", "COMMON"],
  ANALYST: [
    "USER_GET",
    "USER_GET_BY_ID",
    "USER_UPDATE",
    "USER_CRM_REQUESTS_HISTORY_GET",

    "PERSONAL_TASKS_GET",
    "DASHBOARD_CREATE",
    "DASHBOARD_GET",
    "DASHBOARD_UPDATE",
    "DASHBOARD",
    "PERSONAL_TASKS",

    "AWS_GET_BY_ID",
    "AWS_DELETE_BY_ID",

    "CONTACT_GET_WITH_FILTERS",
    "CONTACT",

    "ORDER_GET_FILTERED",
    "ORDER_GET_FILTER",
    "ORDER_GET_POSSIBLE_FIELDS",
    "ORDER_CREATE_FILTER",
    "ORDER_UPDATE_FILTER",
    "ORDER",

    "TICKETS_CHECK_MESSAGE",
    "TICKETS_QUESTION_CREATE",
    "TICKETS_FILE_UPLOAD",
    "TICKETS_CORRECTION_CREATE",
    "TICKETS_ADD_MESSAGE",
    "TICKETS_MARK_CLOSE",

    "TRANSLATION_GET",
    "TRANSLATION_ADD",

    "SEARCH_ORDERS",
    "SEARCH_CONTACT_WITH_ORDERS",
    "CONTACT_UPDATE",
    "MARK_RODO_DECISION",
    "MAIL_SEND_MORE_INFO",
    "MAIL_CONFIRMATION",
    "MAIL_GET_BY_CONTACT",
    "ORDER_GET_WITH_CONTACT",
    "ORDER_RESEND",
    "ORDER_CONFIRM",
    "ORDER_CREATE_SHIPPING",
    "ORDER_EDIT",
    "ORDER_MARK_RESIGNED",
    "ORDER_UPDATE_DELIVERY_DATE",
    "ORDER_MARK_CLAIM",
    "ORDER_GET_HISTORY_BY_ID",
    "TICKETS_CREATE_INVOICE",
    "USER_GET_SHORT_INFO",

    "QUEUE_GET_LIST_OF_INFOS",
    "QUEUE_GET",
    "QUEUE_GET_BY_ID",
    "QUEUE_GET_STACK",
    "QUEUE_UPDATE",
    "QUEUE_GET_POINTS",
    "QUEUE_POSSIBLE_CONTACTS",
    "QUEUE_GET_CURRENT_WORKERS",
    "QUEUE_GROUP_GET",
    "QUEUE_GROUP_DELETE",
    "QUEUE_GROUP_CREATE",
    "QUEUE_GROUP_UPDATE",
    "QUEUE_GROUP_GET_BY_ID",

    "MARKET_GET",
    "MARKET_GET_BY_ID",
    "MARKET_UPDATE",

    "PRODUCT_GET",
    "PRODUCT_GET_BY_ID",
    "PRODUCT_UPDATE",

    "REPORTS_SENT_ORDERS",
    "REPORTS_UTM",
    "REPORTS_CPL",
    "REPORTS_PRODUCT_CPL",
    "REPORTS",

    "QUEUE_GET_STACK_AND_CONTACTS",
    "MAIL_GET_HISTORY",
    "YOUR_TICKETS_DASHBOARD",
    "CREATE_CONTACT_DASHBOARD",
    "MAIL_HISTORY_DASHBOARD",
    "ORDER_SEARCH_DASHBOARD",
    "CURRENT_LEADS_DASHBOARD",
    "MAIL_STATS_DASHBOARD",
    "WPPK_CONFIRMED_MARKET_DASHBOARD",
    "WPPK_DELIVERED_MARKET_DASHBOARD",
    "CONTACT_WEIGHTS_DASHBOARD",

    "LEADS_DASHBOARD",
    "WPPK_DELIVERED_UTM_DASHBOARD",
    "SENT_ORDERS_DASHBOARD",

    "META_CREATE",
    "META_UPDATE",
    "META_GET",
    "META_ADD_SPEND_REPORT",
    "META_CPL_STATS_REPORT",
    "CRM_SETTINGS_META",

    "KEYS_GET_ALL",
    "KEYS_GET_BY_TYPE",

    "COMPANY_GET",

    "INVOICE_CONFIG_GET",

    "DW_UPDATE",
    "TICKET_MARK_SEEN",
    "TICKETS_GET_OLD",
    "UPDATE_TITLE",
    "TICKET_ADD_ATTACHMENTS",

    "CRM_SETTINGS_PROMOTION",
    "PROMOTION_CATEGORY_GET_ALL",
    "PROMOTION_CATEGORY_TEST",
    "PROMOTION_CATEGORY_CREATE",
    "PROMOTION_CATEGORY_UPDATE",
    "PROMOTION_CATEGORY_QUERY",

    "COMMON",
  ],
  SUPPORT: [
    "USER_GET",
    "USER_GET_BY_ID",

    "PERSONAL_TASKS_GET",
    "GENERAL_TASKS_GET",
    "BOUND_GET",
    "BOUND_DELETE",
    "CONTACT_CREATE",
    "DASHBOARD_CREATE",
    "DASHBOARD_GET",
    "DASHBOARD_UPDATE",
    "DASHBOARD",
    "PERSONAL_TASKS",
    "GENERAL_TASKS",
    "BOUND",
    "CONTACT_NEW",

    "CONTACT_GET_WITH_FILTERS",
    "CONTACT",

    "ORDER_GET_ALL_FILTERS",
    "ORDER_GET_FILTERED",
    "ORDER_GET_FILTER",
    "ORDER_GET_POSSIBLE_FIELDS",
    "ORDER_CREATE_FILTER",
    "ORDER_UPDATE_FILTER",
    "ORDER",

    "AWS_GET",
    "AWS_GET_BY_ID",
    "AWS_DELETE_BY_ID",
    "AWS_SAVE_FILE",
    "AWS_SAVE_DRAFT",
    "AWS_GET_RECORDING_BY_ID",

    "CLAIM_CHANGE_STATUS",
    "CLAIM_SEARCH",
    "CLAIM_UPDATE",
    "CLAIM_MARK_DONE",
    "ORDER_MARK_PAID",
    "CLAIMS_BY_PRODUCT",
    "CLAIM_BY_ORDER",

    "TICKETS_UPDATE_INVOICE",
    "TICKETS_UPDATE_DOCUMENT",
    "TICKETS_SEARCH_INVOICES",
    "TICKETS_CHECK_MESSAGE",
    "TICKETS_QUESTION_CREATE",
    "TICKETS_FILE_UPLOAD",
    "TICKETS_CORRECTION_CREATE",
    "TICKETS_ADD_MESSAGE",
    "TICKETS_MARK_CLOSE",
    "TICKETS_MARK_OVERDUE",
    "TICKETS_MARK_TO_CORRECT",
    "TICKETS_MARK_CORRECTED",
    "TICKETS_MARK_ISSUED",
    "TICKETS_MARK_DONE",
    "TICKETS_GET_BY_FILTER",
    "TICKETS_CALLING_IMPROVEMENT_CREATE",
    "TICKETS_ADD_CORRECTION_NUMBER",

    "TRANSLATION_GET",
    "TRANSLATION_SEARCH",
    "TRANSLATION_UPDATE",
    "TRANSLATION_ADD",

    "SEARCH_ORDERS",
    "SEARCH_CONTACT_WITH_ORDERS",
    "CONTACT_UPDATE",
    "MARK_RODO_DECISION",
    "MAIL_SEND_MORE_INFO",
    "MAIL_CONFIRMATION",
    "MAIL_GET_BY_CONTACT",
    "ORDER_GET_WITH_CONTACT",
    "ORDER_RESEND",
    "ORDER_CONFIRM",
    "ORDER_CREATE_SHIPPING",
    "ORDER_EDIT",
    "ORDER_MARK_RESIGNED",
    "ORDER_UPDATE_DELIVERY_DATE",
    "ORDER_MARK_CLAIM",
    "ORDER_GET_HISTORY_BY_ID",
    "TICKETS_CREATE_INVOICE",
    "USER_GET_SHORT_INFO",

    "QUEUE_GET_LIST_OF_INFOS",
    "QUEUE_GET",
    "QUEUE_GET_BY_ID",
    "QUEUE_GET_STACK",
    "QUEUE_POSSIBLE_CONTACTS",
    "QUEUE_UPDATE",
    "QUEUE_GET_POINTS",
    "QUEUE_GET_CURRENT_WORKERS",
    "QUEUE_GROUP_GET",
    "QUEUE_GROUP_DELETE",
    "QUEUE_GROUP_GET_BY_ID",

    "MARKET_GET",
    "MARKET_GET_BY_ID",

    "PRODUCT_CREATE",
    "PRODUCT_GET",
    "PRODUCT_GET_BY_ID",

    "DRAFTS_GET",
    "DRAFTS_CREATE",
    "DRAFTS_DELETE",


    "BLACKBOARD_GET",
    "BLACKBOARD_UPDATE",

    "LISTENING_GENERATOR_CREATE",
    "LISTENING_GENERATOR_GET",
    "LISTENING_GENERATOR_UPDATE",
    "CRM_SETTINGS_LISTENING_GENERATOR",

    "FAQ_GET",
    "CRM_SETTINGS_FAQ",

    "MAIL_ORDERS_QUERY",
    "MAIL_CATEGORY_GET_ALL",
    "MAIL_TEMPLATE_GET_ALL",
    "MAIL_TEST_GET_ALL",
    "MAIL_CATEGORY_VIEW",
    "MAIL",

    "REPORTS_NOT_PICK_UP",
    "REPORTS_CALLING_HISTORY",
    "REPORTS_GRADE",
    "REPORTS_EFFECTIVE_SALARY",
    "REPORTS_WPPK_FOR_MARKET",
    "REPORTS_WPPK_FOR_CONSULTANT",
    "REPORTS_EFFECTIVE",
    "REPORTS_DAILY",
    "REPORTS_SENT_ORDERS",
    "REPORTS_DAILY_MARKET",
    "REPORTS_CONTACTS_ROAD",
    "REPORTS_RESIGN",
    "REPORTS_UTM",
    "REPORTS_ORDER_LANGS_STATS",

    "CALLING_STATISTICS_GET",
    "QUEUE_GET_STACK_AND_CONTACTS",
    "CLAIMS_GET_BY_STATUSES",
    "INVOICES_GET_BY_STATUSES",
    "ARCHIVES_GET_ALL",
    "MAIL_GET_HISTORY",
    "BOUND_INFO_DASHBOARD",
    "YOUR_TICKETS_DASHBOARD",
    "CREATE_CONTACT_DASHBOARD",
    "HOLIDAY_TICKETS_DASHBOARD",
    "MAIL_HISTORY_DASHBOARD",
    "EFFECTIVITY_REPORT_DASHBOARD",
    "ORDER_SEARCH_DASHBOARD",

    "DW_UPDATE",
    "TICKET_MARK_SEEN",
    "TICKETS_GET_OLD",
    "UPDATE_TITLE",
    "TICKET_ADD_ATTACHMENTS",

    "VALID_INVOICES_GET",
    "ACCOUNTING_CLAIMS",
    "ACCOUNTING",

    "GET_COMPANY_DATA_BY_VAT",
    "GET_COMPANY_DATA_BY_NUMBER",
    "VALID_INVOICES_REPORT_GET",
    "VALID_INVOICE_CREATE",
    "GENERATE_INVOICE_PREVIEW",
    "VALID_INVOICE_UPDATE",
    "VALID_INVOICE_ACCEPT",
    "VALID_INVOICES_GET",

    "KEYS_GET_ALL",
    "KEYS_GET_BY_TYPE",

    "CREATE_LANUGAGE",
    "UPDATE_LANGUAGE",

    "COMPANY_GET",

    "INVOICE_CONFIG_GET",

    "CMS_REFUND",
    "CONSULTANT_MANAGER",
    "COMMON",
    "CONSULTANT_COMMON",

    "VALID_CLAIM_CREATE",
    "VALID_CLAIMS_GET",
    "VALID_CLAIMS_UPDATE",
    "VALID_CLAIMS_DASHBOARD",
  ],
  FINANCE_ANALYST: [
    "USER_GET",
    "USER_GET_BY_ID",
    "USER_UPDATE",
    "USER_CRM_REQUESTS_HISTORY_GET",

    "DASHBOARD_CREATE",
    "DASHBOARD_GET",
    "DASHBOARD_UPDATE",
    "PERSONAL_TASKS_GET",
    "DASHBOARD",
    "PERSONAL_TASKS",

    "CONTACT_GET_WITH_FILTERS",
    "CONTACT",

    "ORDER_GET_FILTERED",
    "ORDER_GET_FILTER",
    "ORDER_GET_POSSIBLE_FIELDS",
    "ORDER_CREATE_FILTER",
    "ORDER_UPDATE_FILTER",
    "ORDER",

    "AWS_GET",
    "AWS_GET_BY_ID",
    "AWS_DELETE_BY_ID",
    "AWS_SAVE_FILE",

    "CLAIM_CHANGE_STATUS",
    "CLAIM_SEARCH",
    "CLAIM_UPDATE",
    "CLAIM_MARK_DONE",
    "ORDER_MARK_PAID",
    "CLAIMS_BY_PRODUCT",
    "CLAIM_BY_ORDER",
    "ACCOUNTING_CLAIMS_REPORT_GET",
    "ACCOUNTING_INVOICES_REPORT_GET",
    "ACCOUNTING",
    "ACCOUNTING_CLAIMS_SEARCH",
    "ACCOUNTING_CLAIMS",
    "ACCOUNTING_INVOICES",
    "ACCOUNTING_CLAIMS_REPORT",
    "ACCOUNTING_INVOICES_REPORT",

    "CC_SEARCH",
    "CC_ACCEPT",
    "CC_SALARY_EFFECTIVE",
    "CC_SALARY_EFFECTIVE_KDR",
    "CC_SAVE_CONSULTANT_HOURS",
    "CC",
    "CC_HOURS",
    "CC_HOURS_REPORT",
    "CC_PAYMENT",
    "CC_PAYMENT_KDR",
    "CC_SPEECH_TO_TEXT",

    "TICKETS_UPDATE_INVOICE",
    "TICKETS_SEARCH_INVOICES",
    "TICKETS_CHECK_MESSAGE",
    "TICKETS_QUESTION_CREATE",
    "TICKETS_FILE_UPLOAD",
    "TICKETS_CORRECTION_CREATE",
    "TICKETS_ADD_MESSAGE",
    "TICKETS_MARK_CLOSE",
    "TICKETS_MARK_OVERDUE",
    "TICKETS_MARK_TO_CORRECT",
    "TICKETS_MARK_CORRECTED",
    "TICKETS_MARK_ISSUED",
    "TICKETS_ADD_CORRECTION_NUMBER",
    "TICKETS_MARK_DONE",

    "TRANSLATION_GET",
    "TRANSLATION_ADD",

    "SEARCH_ORDERS",
    "SEARCH_CONTACT_WITH_ORDERS",
    "CONTACT_UPDATE",
    "MARK_RODO_DECISION",
    "MAIL_SEND_MORE_INFO",
    "MAIL_CONFIRMATION",
    "MAIL_GET_BY_CONTACT",
    "ORDER_GET_WITH_CONTACT",
    "ORDER_RESEND",
    "ORDER_CONFIRM",
    "ORDER_CREATE_SHIPPING",
    "ORDER_EDIT",
    "ORDER_MARK_RESIGNED",
    "ORDER_UPDATE_DELIVERY_DATE",
    "ORDER_MARK_CLAIM",
    "ORDER_GET_HISTORY_BY_ID",
    "TICKETS_CREATE_INVOICE",
    "USER_GET_SHORT_INFO",

    "QUEUE_GET_LIST_OF_INFOS",
    "QUEUE_GET",
    "QUEUE_GET_BY_ID",
    "QUEUE_GET_STACK",
    "QUEUE_UPDATE",
    "QUEUE_GET_POINTS",
    "QUEUE_POSSIBLE_CONTACTS",
    "QUEUE_GET_CURRENT_WORKERS",
    "QUEUE_GROUP_GET",
    "QUEUE_GROUP_DELETE",
    "QUEUE_GROUP_CREATE",
    "QUEUE_GROUP_UPDATE",
    "QUEUE_GROUP_GET_BY_ID",

    "MARKET_GET",
    "MARKET_GET_BY_ID",
    "MARKET_UPDATE",

    "PRODUCT_GET",
    "PRODUCT_GET_BY_ID",
    "PRODUCT_UPDATE",

    "CLAIMS_GET_BY_STATUSES",
    "INVOICES_GET_BY_STATUSES",
    "ARCHIVES_GET_ALL",
    "MAIL_GET_HISTORY",
    "BOUND_INFO_DASHBOARD",
    "YOUR_TICKETS_DASHBOARD",
    "CREATE_CONTACT_DASHBOARD",
    "MAIL_HISTORY_DASHBOARD",
    "CONSULTANT_HOURS_DASHBOARD",
    "ARCHIVES_DASHBOARD",
    "ORDER_SEARCH_DASHBOARD",
    "INVOICES_DASHBOARD",
    "CLAIMS_DASHBOARD",

    "DW_UPDATE",
    "TICKET_MARK_SEEN",
    "TICKETS_GET_OLD",
    "UPDATE_TITLE",
    "TICKET_ADD_ATTACHMENTS",

    "GET_COMPANY_DATA_BY_VAT",
    "GET_COMPANY_DATA_BY_NUMBER",
    "CREATE_LANUGAGE",
    "UPDATE_LANGUAGE",
    "VALID_INVOICES_REPORT_GET",
    "VALID_INVOICE_CREATE",
    "GENERATE_INVOICE_PREVIEW",
    "VALID_INVOICE_UPDATE",
    "VALID_INVOICE_ACCEPT",
    "VALID_INVOICES_GET",

    "CRM_SETTINGS_INVOICE_TEMPLATE",
    "INVOICE_TEMPLATE_CREATE",
    "INVOICE_TEMPLATE_UPDATE",
    "INVOICE_TEMPLATE_GET",

    "KEYS_GET_ALL",
    "KEYS_GET_BY_TYPE",

    "COMPANY_GET",

    "INVOICE_CONFIG_GET",
  
    "CMS_REFUND",
    "COMMON",

    "VALID_CLAIM_CREATE",
    "VALID_CLAIMS_GET",
    "VALID_CLAIMS_UPDATE",
    "VALID_CLAIMS_PAY",
    "VALID_CLAIMS_FINANCE_DASHBOARD",
  ],
  HEAD_CC_MANAGER: [
    "USER_GET",
    "USER_CREATE_BY_CSV",
    "USER_UPDATE",
    "USER_CREATE",
    "USER_GET_BY_ID",
    "USER_CRM_REQUESTS_HISTORY_GET",
    "CRM_SETTINGS_USERS",

    "GENERAL_TASKS_GET",
    "PERSONAL_TASKS_GET",
    "BOUND_GET",
    "BOUND_DELETE",
    "CONTACT_CREATE",
    "DASHBOARD_CREATE",
    "DASHBOARD_GET",
    "DASHBOARD_UPDATE",
    "DASHBOARD",
    "PERSONAL_TASKS",
    "GENERAL_TASKS",
    "BOUND",
    "CONTACT_NEW",

    "CONTACT_GET_WITH_FILTERS",
    "CONTACT",

    "ORDER_GET_FILTERED",
    "ORDER_GET_FILTER",
    "ORDER_GET_POSSIBLE_FIELDS",
    "ORDER_CREATE_FILTER",
    "ORDER_UPDATE_FILTER",
    "ORDER",

    "AWS_GET",
    "AWS_GET_BY_ID",
    "AWS_DELETE_BY_ID",
    "AWS_SAVE_FILE",
    "AWS_SAVE_DRAFT",
    "AWS_GET_RECORDING_BY_ID",

    "CLAIM_CHANGE_STATUS",
    "CLAIM_SEARCH",
    "CLAIM_UPDATE",
    "CLAIM_MARK_DONE",
    "ORDER_MARK_PAID",
    "CLAIMS_BY_PRODUCT",
    "CLAIM_BY_ORDER",
    "ACCOUNTING_CLAIMS_REPORT_GET",
    "ACCOUNTING_INVOICES_REPORT_GET",
    "ACCOUNTING",
    "ACCOUNTING_CLAIMS_SEARCH",
    "ACCOUNTING_CLAIMS",
    "ACCOUNTING_INVOICES",
    "ACCOUNTING_CLAIMS_REPORT",
    "ACCOUNTING_INVOICES_REPORT",

    "CC_SEARCH",
    "CC_ACCEPT",
    "CC_SALARY_EFFECTIVE",
    "CC_SALARY_EFFECTIVE_KDR",
    "CC_SAVE_CONSULTANT_HOURS",
    "CC",
    "CC_HOURS",
    "CC_HOURS_REPORT",
    "CC_PAYMENT",
    "CC_PAYMENT_KDR",
    "CC_SPEECH_TO_TEXT",
    "CC_RECRUITMENT",

    "TICKETS_UPDATE_INVOICE",
    "TICKETS_UPDATE_DOCUMENT",
    "TICKETS_SEARCH_INVOICES",
    "TICKETS_CHECK_MESSAGE",
    "TICKETS_QUESTION_CREATE",
    "TICKETS_FILE_UPLOAD",
    "TICKETS_CORRECTION_CREATE",
    "TICKETS_ADD_MESSAGE",
    "TICKETS_MARK_CLOSE",
    "TICKETS_MARK_OVERDUE",
    "TICKETS_MARK_TO_CORRECT",
    "TICKETS_MARK_CORRECTED",
    "TICKETS_MARK_ISSUED",
    "TICKETS_MARK_DONE",
    "TICKETS_CALLING_IMPROVEMENT_CREATE",
    "TICKETS_ADD_CORRECTION_NUMBER",
    "TICKETS_GET_BY_FILTER",

    "TRANSLATION_GET",
    "TRANSLATION_UPDATE",
    "TRANSLATION_SEARCH",
    "TRANSLATION_ADD",
    "CRM_SETTINGS_DICTIONARY",

    "SEARCH_ORDERS",
    "SEARCH_CONTACT_WITH_ORDERS",
    "CONTACT_UPDATE",
    "MARK_RODO_DECISION",
    "MAIL_SEND_MORE_INFO",
    "MAIL_CONFIRMATION",
    "MAIL_GET_BY_CONTACT",
    "ORDER_GET_WITH_CONTACT",
    "ORDER_RESEND",
    "ORDER_CONFIRM",
    "ORDER_CREATE_SHIPPING",
    "ORDER_EDIT",
    "ORDER_MARK_RESIGNED",
    "ORDER_UPDATE_DELIVERY_DATE",
    "ORDER_MARK_CLAIM",
    "ORDER_GET_HISTORY_BY_ID",
    "TICKETS_CREATE_INVOICE",
    "USER_GET_SHORT_INFO",

    "QUEUE_CREATE",
    "QUEUE_GET_LIST_OF_INFOS",
    "QUEUE_GET",
    "QUEUE_GET_BY_ID",
    "QUEUE_GET_STACK",
    "QUEUE_UPDATE",
    "QUEUE_GET_POINTS",
    "QUEUE_POSSIBLE_CONTACTS",
    "QUEUE_GET_CURRENT_WORKERS",
    "QUEUE_GROUP_GET",
    "QUEUE_GROUP_DELETE",
    "QUEUE_GROUP_CREATE",
    "QUEUE_GROUP_UPDATE",
    "QUEUE_GROUP_GET_BY_ID",
    "CRM_SETTINGS_QUEUES",

    "MARKET_CREATE",
    "MARKET_GET",
    "MARKET_GET_BY_ID",
    "MARKET_UPDATE",
    "CRM_SETTINGS_MARKETS",

    "PRODUCT_CREATE",
    "PRODUCT_GET",
    "PRODUCT_GET_BY_ID",
    "PRODUCT_UPDATE",
    "CRM_SETTINGS_PRODUCTS",

    "DRAFTS_GET",
    "DRAFTS_CREATE",
    "DRAFTS_UPDATE",
    "CRM_SETTINGS_DRAFTS",

    "BLACKBOARD_GET",
    "BLACKBOARD_UPDATE",
    "BLACKBOARD_CREATE",
    "CRM_SETTINGS_BLACKBOARD",

    "LISTENING_GENERATOR_CREATE",
    "LISTENING_GENERATOR_GET",
    "LISTENING_GENERATOR_UPDATE",
    "CRM_SETTINGS_LISTENING_GENERATOR",

    "FAQ_GET",
    "FAQ_UPDATE",
    "FAQ_CREATE",
    "CRM_SETTINGS_FAQ",

    "REPORTS_NOT_PICK_UP",
    "REPORTS_CALLING_HISTORY",
    "REPORTS_GRADE",
    "REPORTS_EFFECTIVE_SALARY",
    "REPORTS_WPPK_FOR_MARKET",
    "REPORTS_WPPK_FOR_CONSULTANT",
    "REPORTS_EFFECTIVE",
    "REPORTS_DAILY",
    "REPORTS_SENT_ORDERS",
    "REPORTS_DAILY_MARKET",
    "REPORTS_CONTACTS_ROAD",
    "REPORTS_SUSPICIONS",
    "REPORTS",

    "MAIL_SEARCH",
    "MAIL",
    "MAIL_ORDERS_QUERY",
    "MAIL_CATEGORY_GET_ALL",
    "MAIL_CATEGORY_CREATE",
    "MAIL_CATEGORY_UPDATE",
    "MAIL_TEMPLATE_GET_ALL",
    "MAIL_TEMPLATE_CREATE",
    "MAIL_TEMPLATE_UPDATE",
    "MAIL_CATEGORY_TEST",
    "MAIL_TEST_GET_ALL",
    "MAIL_TEST_CREATE",
    "MAIL_TEST_UPDATE",
    "MAIL_RECRUITMENT_CATEGORY",

    "VOIP_FIND_SMS",
    "CLOUD_TALK_GET_NUMBERS",
    "SMS_GET_TEMPLATE",
    "SMS_UPDATE_TEMPLATE",
    "DELETE_SMS_TEMPLATE",
    "SMS_DELETE_TEMPLATE",
    "SMS_CREATE_TEMPLATE",
    "CALLING_GET_BY_FILTER",
    "VOIP_GET_REALTIME_DATA",
    "VOIP_GET_MARKET_STATISTICS",
    "REPORT_VOIP_WORKING_TIME",
    "VOIP_GET_SPECIAL_MARKET_STATISTICTS",
    "VOIP_GET_ADMIN_RECORDED_CALLS",
    "VOIP_LISTENING_SCORING_REPORT_GET",
    "VOIP_LISTENING_SCORING_REPORT_UPDATE",
    "ORDER_GET_ARCHIVES",
    "ORDER_ADD_ARCHIVES",
    "VOIP_SMS_SEARCH",
    "VOIP_SMS_CREATE",
    "VOIP_CALLING_SEARCH",
    "VOIP_MARKS",
    "VOIP_REALTIME",
    "VOIP_WORKING_TIME",
    "VOIP_STATISTICS",
    "VOIP_LISTENING",
    "VOIP_SCORING",
    "VOIP_ARCHIVE",
    "VOIP",

    "LISTENING_GENERATOR_DELETE",

    "CALLING_STATISTICS_GET",
    "ARCHIVES_GET_ALL",
    "BOUND_INFO_DASHBOARD",
    "YOUR_TICKETS_DASHBOARD",
    "CREATE_CONTACT_DASHBOARD",
    "HOLIDAY_TICKETS_DASHBOARD",
    "LOGGED_IN_USERS_DASHBOARD",
    "EFFECTIVITY_REPORT_DASHBOARD",
    "CONSULTANT_HOURS_DASHBOARD",
    "ARCHIVES_DASHBOARD",
    "ORDER_SEARCH_DASHBOARD",
    "SENT_ORDERS_DASHBOARD",
    "VOIP_STATISTICS_DASHBOARD",
    "CALLING_STATISTICS_DASHBOARD",

    "CALLING_STATS_DASHBOARD",
    "CONSULTANT_INFO_DASHBOARD",
    "DONUT_CALLING_STATS_DASHBOARD",
    "HOURS_CONSULTANT_DASHBOARD",
    "LEADS_DASHBOARD",
    "WPPK_CONFIRMED_DASHBOARD",
    "WPPK_DELIVERED_DASHBOARD",
    "DONUT_MARKET_STATS_DASHBOARD",
    "DELIVERY_MARKET_DASHBOARD",
    "DELIVERY_CONSULTANT_DASHBOARD",
    "CONTACT_WEIGHTS_DASHBOARD",
    "WPPK_CONFIRMED_MARKET_DASHBOARD",
    "WPPK_DELIVERED_MARKET_DASHBOARD",

    "CRM_SETTINGS",

    "DW_UPDATE",
    "TICKET_MARK_SEEN",
    "TICKETS_GET_OLD",
    "UPDATE_TITLE",
    "TICKET_ADD_ATTACHMENTS",

    "CC_GAMIFICATION",
    "GAMIFICATION_CONFIG_CREATE",
    "GAMIFICATION_CONFIG_GET",
    "GAMIFICATION_CONFIG_GET_ALL",
    "GAMIFICATION_CONFIG_UPDATE",
    "GAMIFICATION_STATS_GET",

    "KEYS_GET_ALL",
    "KEYS_GET_BY_TYPE",

    "COMPANY_GET",

    "INVOICE_CONFIG_GET",

    "COMMON",
    "GET_ADMIN_TODOS",

    "CRM_SETTINGS_RECRUITMENT",
    "CRM_SETTINGS_POSITION",
    "CRM_SETTINGS_DYNAMIC_MAIL_CONTENT",
    "CRM_SETTINGS_DYNAMIC_MAIL_ATTACHMENT",
    "CRM_SETTINGS_FILES",


    "CRM_SETTINGS_BONUS",
    "BONUS_CREATE",
    "BONUS_UPDATE",

    "GET_CALL_CENTER_CANDIDATES",
    "CREATE_CALL_CENTER_CANDIDATE",
    "UPDATE_CALL_CENTER_CANDIDATE",
    "GET_CALL_CENTER_POSITIONS",
    "CREATE_CALL_CENTER_POSITION",
    "UPDATE_CALL_CENTER_POSITION",
    "GET_DYNAMIC_MAIL_ATTACHMENT",
    "CREATE_DYNAMIC_MAIL_ATTACHMENT",
    "UPDATE_DYNAMIC_MAIL_ATTACHMENT",
    "GET_DYNAMIC_MAIL_CONTENT",
    "CREATE_DYNAMIC_MAIL_CONTENT",
    "UPDATE_DYNAMIC_MAIL_CONTENT",
    "GET_FILES",
    "CREATE_FILE",
    "UPDATE_FILE",
    "MAIL_RECRUITMENT_CATEGORY_GET_ALL",
    "MAIL_RECRUITMENT_CATEGORY_TEST",
    "MAIL_RECRUITMENT_CATEGORY_CREATE",
    "MAIL_RECRUITMENT_CATEGORY_UPDATE",
    "MAIL_CANDIDATES_QUERY",
    "GET_RECRUITMENT_PAGES",
    "CREATE_RECRUITMENT_PAGE",
    "UPDATE_RECRUITMENT_PAGE",
  ],
};

class UserManager {
  setUser(user) {
    let payload = JSON.stringify(user);
    localStorage.setItem("user", payload);
  }

  getUser() {
    return localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  getAllRoles() {
    return Object.keys(ACCESSES);
  }

  getAllAcesses() {
    return ALL_ACCESSES;
  }

  getRolesAccesses(role) {
    return ACCESSES[role];
  }

  setCommonData(commonData) {
    localStorage.setItem("common_data", JSON.stringify(commonData));
  }

  getCommonData() {
    return JSON.parse(localStorage.getItem("common_data"));
  }

  checkAccess(access) {
    return (
      this.getUser().role === "ADMIN" ||
      this.getUser()?.access?.includes(access)
    );
  }
}

export default new UserManager();
