import PopUp from '@/common/components/PopUp'
import { Subtitle } from '@/common/components/validClaimForm/ValidClaimForm.styled'
import React, { useEffect } from 'react'
import { ButtonWrapper, Textarea } from './AddMessage.styled'
import CustomButtonAdd from '@/common/components/buttons/CustomButtonAdd'

const AddMessage = ({ messageRef, status, setShow, send }) => {

  const handleSend = () => {
    setShow(false);
    send(status, messageRef.current?.value);
  }

  useEffect(() => {
    messageRef?.current.focus();
  }, [messageRef?.current])

  return (
    <PopUp padding='10px 20px 16px' setShow={setShow}>
      <Subtitle>Add message to change status to "{status}":</Subtitle>
      <Textarea placeholder={"Type..."} ref={messageRef} />
      <ButtonWrapper>
        <CustomButtonAdd
          onClick={handleSend} 
          defaultText="Send" 
        />
      </ButtonWrapper>
    </PopUp>
  )
}

export default AddMessage
